<template>
  <div>
    <b-card>
      <b-button variant="primary" v-b-modal.packageModal>
        <feather-icon icon="PlusIcon"></feather-icon>
      </b-button>
      <b-table
        small
        responsive
        hover
        class="mt-3"
        :items="priceList"
        :fields="fields"
        primary-key="id"
      >
        <template #cell(discount)="data">
          <span>{{ data.item.discount }}%</span>
        </template>
        <template #cell(total)="data">
          <span>{{ data.item.amount * data.item.pricePer }}</span>
        </template>
        <template #cell(actions)="data">
          <b-button variant="outline-success" @click="showModal(data)">
            <feather-icon icon="EditIcon"></feather-icon>
          </b-button>
          <b-button
            class="ml-1"
            variant="outline-danger"
            @click="deleteItem(data.item._id)"
          >
            <feather-icon icon="XIcon"> </feather-icon>
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-modal
      ref="packageModal"
      id="packageModal"
      centered
      hide-footer
      @hide="hiddenModal"
      :title="$t('Package Info')"
    >
      <b-row class="mt-1 justify-content-center align-items-center">
        <b-col sm="3">
          <label>{{ $t("Title") }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input v-model="sendData.title" :placeholder="$t('Title')" />
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center align-items-center">
        <b-col sm="3">
          <label>{{ $t("Subtitle") }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input v-model="sendData.subtitle" :placeholder="$t('Subtitle')" />
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center align-items-center">
        <b-col sm="3">
          <label>{{ $t("Amount") }}</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            type="number"
            v-model="sendData.amount"
            :placeholder="$t('Amount')"
            min="1"
            required
          />
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center align-items-center">
        <b-col sm="3">
          <label>{{ $t("Price Per") }}</label>
        </b-col>
        <b-col sm="8">
          <b-form-input
            type="number"
            v-model="sendData.pricePer"
            :placeholder="$t('Price Per')"
            required
            min="1"
          />
        </b-col>
        <b-col sm="1">
          <span>TL</span>
        </b-col>
      </b-row>
      <b-row class="mt-1 justify-content-center align-items-center" v-show="showDiscount">
        <b-col sm="3">
          <label>{{ $t("Discount") }}</label>
        </b-col>
        <b-col sm="8">
          <b-form-input
            type="number"
            v-model="sendData.discount"
            :placeholder="$t('Discount')"
            min="0"
          />
        </b-col>
        <b-col sm="1">
          <span>%</span>
        </b-col>
      </b-row>
      <b-form-textarea
        class="mt-1"
        v-model="planbenefits"
        rows="10"
        :placeholder="$t('Plan Benefits')"
      />
      <b-button
        variant="outline-success"
        @click="
          updateItem(sendData._id);
          $refs.packageModal.hide();
        "
        class="mt-1 float-right"
      >
        <feather-icon icon="SaveIcon"></feather-icon>
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BTable,
  BButton,
  BLink,
  BModal,
  BCard,
  BBadge,
  BCol,
  BFormInput,
  BFormTextarea,
  BRow,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
const {
  axiosRoutes: {
    productPackage: {
      new: apiNew,
      getAll: apiGetList,
      getOne: apiGet,
      update: apiPut,
      delete: apiDelete,
    },
  },
} = require("/G_CONFIG");
export default {
  components: {
    BTable,
    BRow,
    BFormCheckbox,
    BButton,
    BLink,
    BModal,
    BCard,
    BBadge,
    BCol,
    BFormInput,
    BFormTextarea,
  },

  data() {
    return {
      sendData: {
        title: "",
        subtitle: "",
        amount: 1,
        pricePer: 1,
        discount: 0,
        total: 0,
        planBenefits: [],
        _id: null,
      },
      planbenefits: "",
      fields: [
        { key: "title", label: this.$t("Title") },
        { key: "subtitle", label: this.$t("Subtitle") },
        { key: "amount", label: this.$t("Amount") },
        { key: "pricePer", label: this.$t("PricePer") },
        { key: "total", label: this.$t("Total") },
        { key: "discount", label: this.$t("Discount") },
        { key: "actions", label: this.$t("Actions") },
      ],
    };
  },
  created() {
    console.log("created");
  },
  setup() {
    let priceList = ref([]);
    let showDiscount = ref(true);
    console.log("setup");
    axios.get(apiGetList).then(({ data }) => {
      priceList.value = data;
      if (data.filter((item) => item.amount == 1).length > 0) {
        showDiscount.value = false;
      } else {
        showDiscount.value = true;
      }
    });
    return { priceList, showDiscount };
  },
  mounted() {
    console.log("mounted");
  },
  methods: {
    getList() {
      console.log("in getList");
      axios.get(apiGetList).then(({ data }) => {
        this.priceList = data;
        if (data.filter((item) => item.amount == 1).length > 0) {
          this.showDiscount = false;
        } else {
          this.showDiscount = true;
        }
      });
    },
    hiddenModal() {
      this.sendData = {
        title: "",
        subtitle: "",
        amount: 1,
        pricePer: 1,
        discount: 0,
        planBenefits: [],
        total: 0,
        _id: null,
      };
      this.planbenefits = "";
    },
    showModal(data = null) {
      console.log("in showModal");
      if (data) {
        const {
          title,
          subtitle,
          amount,
          pricePer,
          discount,
          planBenefits,
          _id,
        } = data.item;
        this.sendData = {
          title,
          subtitle,
          amount,
          pricePer,
          discount,
          planBenefits,
          _id,
        };
        this.planbenefits = this.sendData.planBenefits.join("\n");
      }
      this.$refs.packageModal.show();
    },
    updateItem(id = null) {
      //validate amount or pricePer are not found in this.priceList.value array

      if (this.sendData.amount < 1 || this.sendData.pricePer < 1) {
        this.$bvToast.toast("Amount and Price Per must be greater than 0", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }
      if (id !== null) {
        axios.put(apiPut + id, this.sendData).then(({ data }) => {
          // this.priceList.value = this.priceList.value.map((item) => {
          //   if (item._id === id) {
          //     return data;
          //   }
          //   return item;
          // });
          this.getList();
        });
      } else {
        if (
          this.priceList.filter(
            (item) =>
              item.amount == this.sendData.amount ||
              item.pricePer == this.sendData.pricePer
          ).length > 0
        ) {
          this.$bvToast.toast("Amount or Price Per already exists", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          return;
        }
        axios.post(apiNew, this.sendData).then(({ data }) => {
          this.priceList.value.push(data);
          this.priceList.value.sort((a, b) => (a.amount > b.amount ? 1 : -1));
        });
      }
    },
    baseExists() {
      const response =
        this.priceList.value.filter((item) => item.amount == 1).length == 1;
      console.log(response);
      return response;
    },
    deleteItem(id) {
      console.log("in deleteItem");
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        cancelButtonText: this.$t("Cancel"),
        confirmButtonText: this.$t("Confirm"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          axios.delete(apiDelete + id).then(() => {
            this.getList();
          });
        }
      });
    },
  },
  computed: {},
  watch: {
    planbenefits(newValue) {
      console.log("pbwatch ", newValue);
      this.sendData.planBenefits = this.planbenefits.split("\n");
    },
  },
  created() {},
  mounted() {},
  destroyed() {},
};
</script>
<style lang="scss" scoped></style>
